import React, {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useState,
} from "react";
import { Box } from "../entities/box.entity";
import { Item } from "../entities/item.entity";

interface AppContextInterface {
  currentTab: number;
  loading: boolean;
  box: Box | undefined;
  item: Item | undefined;
  showCLModal: boolean;
  showADModal: boolean;
  setCurrentTab: Dispatch<SetStateAction<number>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setBox: Dispatch<SetStateAction<Box | undefined>>;
  setItem: Dispatch<SetStateAction<Item | undefined>>;
  setShowCLModal: Dispatch<SetStateAction<boolean>>;
  setShowADModal: Dispatch<SetStateAction<boolean>>;
}

//initialize expmty context
export const Context = createContext<AppContextInterface>({
  currentTab: 0,
  loading: false,
  showCLModal: false,
  showADModal: false,
  box: undefined,
  item: undefined,
  setCurrentTab: () => ({}),
  setLoading: () => ({}),
  setBox: () => ({}),
  setItem: () => ({}),
  setShowCLModal: () => ({}),
  setShowADModal: () => ({}),
});

export function AppContextProvider({ children }: { children: ReactElement }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(-1);
  const [showCLModal, setShowCLModal] = useState<boolean>(false); //change location modal
  const [showADModal, setShowADModal] = useState<boolean>(false); //change location modal
  const [box, setBox] = useState<Box>(); //change location modal
  const [item, setItem] = useState<Item>(); //change location modal

  return (
    <>
      <Context.Provider
        value={{
          currentTab,
          loading,
          box,
          item,
          showCLModal,
          showADModal,
          setCurrentTab,
          setLoading,
          setBox,
          setItem,
          setShowCLModal,
          setShowADModal,
        }}
      >
        {children}
      </Context.Provider>
    </>
  );
}
