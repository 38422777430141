import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/header/header";
import { Typography } from "@mui/material";
import { MainWrapper, ContentWrapper } from "./wrapper";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
export default function MainLayout() {
  return (
    <>
      <MainWrapper>
        <Header>
          <Typography
            data-cy="header"
            sx={{
              fontFamily: "Roboto",
              fontSize: "2rem",
              color: "black",
              fontWeight: "400",
            }}
          >
            Search Location
          </Typography>
          <div
            onClick={() => {
              localStorage.clear();
              location.href = "/";
            }}
          >
            <ArrowLeftOnRectangleIcon
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        </Header>
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </MainWrapper>
    </>
  );
}
