import React from "react";
import styled from "@emotion/styled";
import logo from "../../assets/logo.png";

const LogoWrapper = styled.div`
  width: 13rem;
`;

const LogoImage = styled.img`
  width: 100%;
`;

export default function Logo() {
  return (
    <>
      <LogoWrapper>
        <LogoImage src={logo} />
      </LogoWrapper>
    </>
  );
}
