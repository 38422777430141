import React from "react";
import LoginForm from "../components/login/form";
import Logo from "../components/login/logo";
import LoginWrapper from "../components/login/wrapper";
import Typography from "@mui/material/Typography";

export default function Login() {
  return (
    <>
      <LoginWrapper>
        <Logo />
        <Typography sx={{ margin: "1rem", fontWeight: "500", fontSize: 22 }}>
          Session Start
        </Typography>
        <LoginForm />
      </LoginWrapper>
    </>
  );
}
