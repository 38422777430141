import React from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { FormControl, Switch } from "@mui/material";

interface SwitchProps {
  id: string;
  control: Control<FieldValues>;
  errors?: FieldErrors<FieldValues>;
  rows?: number;
  testId: string;
  InputProps?: object;
  defaultValue?: boolean;
}

export default function SwitchInput({
  id,
  control,
  testId,
  defaultValue = false,
}: SwitchProps) {
  return (
    <Controller
      defaultValue={defaultValue}
      name={id}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormControl>
          <Switch checked={value} onChange={onChange} data-cy={testId} />
        </FormControl>
      )}
    />
  );
}
