import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { Context } from "../../context/app_context";
import { createNewBox } from "../../services/box/box";

const NewBoxWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function CreateNewBox() {
  const { setLoading, setBox } = useContext(Context);

  const onClick = async () => {
    setLoading(true);
    const createResponse = await createNewBox();
    if (createResponse.status === 200) {
      setBox(createResponse.data);
      toast.success("Box created");
    } else {
      toast.error("Can't create the box");
    }
    setLoading(false);
  };
  return (
    <>
      <NewBoxWrapper>
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontSize: "1rem",
            borderRadius: 100,
            color: "white",
            paddingX: "2rem",
            gridArea: "1 / 1 / 3 / 3",
          }}
          onClick={onClick}
        >
          Create New Box
        </Button>
      </NewBoxWrapper>
    </>
  );
}
