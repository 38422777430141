import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import React, { useContext } from "react";
import { Context } from "../../context/app_context";
import AddItem from "../add_item/add_item";
import ChangeLocation from "../change_location/change_location";
import { BoxInfoWrapper, TextWrapper } from "./info_components";

export default function BoxInfo() {
  const { box, setShowCLModal } = useContext(Context);
  if (box) {
    return (
      <>
        <ChangeLocation />
        <AddItem />
        <BoxInfoWrapper>
          <Typography variant="h5">Box Location {box.location.code}</Typography>
          <TextWrapper
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: "1rem",
                borderRadius: 100,
                color: "white",
                paddingX: "2rem",
                gridArea: "1 / 1 / 3 / 3",
              }}
              data-cy="change_location_button"
              onClick={() => setShowCLModal(true)}
            >
              Change Location
            </Button>
          </TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Box id:</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {box.id}
            </Typography>
          </TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Is Extra Location:</Typography>
            <Typography variant="body1" fontWeight={"bold"} textAlign="right">
              {box.is_extra_location ? "SI" : "NO"}
            </Typography>
          </TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Updated By:</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {box.user_last_update}
            </Typography>
          </TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Last Update:</Typography>
            <Typography variant="body1" fontWeight={"bold"} textAlign="right">
              {format(new Date(box.updated_at), "MM/dd/yyyy")}
            </Typography>
          </TextWrapper>
        </BoxInfoWrapper>
      </>
    );
  } else {
    return <></>;
  }
}
