import styled from "@emotion/styled";

export const ItemImageWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 5/1/5/4;
`;
export const ItemImage = styled.img`
  height: 100%;
`;

export const ItemInfoWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
  min-height: 6rem;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  margin: 1rem;
  margin-left: 0;
  margin-right: 0;
`;

export const TextWrapper = styled.div`
  width: 100%;
  align-self: center;
  justify-self: flex-start;
  grid-area: ${(props: { gridArea?: string }) =>
    props.gridArea ? props.gridArea : "unset"};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  font-size: 12px;
`;
