import React from "react";
import TextField from "@mui/material/TextField";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { FormControl } from "@mui/material";

interface InputTextProps {
  id: string;
  label: string;
  control: Control<FieldValues>;
  errors: FieldErrors<FieldValues>;
  required: boolean;
  type?: string;
  defaultHelperText?: string;
  rows?: number;
  submitOnEnter?: boolean;
  InputProps?: object;
  inputProps?: object;
  defaultValue?: string;
  sx?: object;
  testId: string;
  autoFocus?: boolean;
}

export default function InputText({
  id,
  label,
  type = "text",
  control,
  errors,
  required,
  defaultHelperText,
  rows = 0,
  InputProps,
  inputProps,
  testId,
  defaultValue = " ",
  sx,
  autoFocus = false,
}: InputTextProps) {
  return (
    <Controller
      defaultValue={defaultValue}
      name={id}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormControl>
          <TextField
            label={label}
            value={value}
            rows={rows}
            multiline={rows !== 0}
            required={required}
            fullWidth
            inputProps={{
              ...inputProps,
              "data-cy": testId,
            }}
            type={type}
            sx={{ mt: 0, ...sx }}
            margin={"normal"}
            InputProps={InputProps}
            onChange={onChange}
            helperText={
              errors[id] ? (errors[id]?.message as string) : defaultHelperText
            }
            error={!!(errors[id] && errors[id]?.type)}
            autoFocus={autoFocus}
          />
        </FormControl>
      )}
    />
  );
}
