import styled from "@emotion/styled";

export const MainWrapper = styled.div`
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  width: calc(100vw);
`;

export const ContentWrapper = styled.div`
  margin-top: 1rem;
  background-color: #eeeeee;
  min-height: calc(100vh - 4.5rem);
  padding: 1rem;
  padding-top: 0;
  box-sizing: border-box;
`;
