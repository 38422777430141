import React, { ReactElement } from "react";

export function ErrorMessage({
  testId,
  children,
}: {
  testId: string;
  children: ReactElement | string;
}) {
  return <p data-cy={testId}>{children}</p>;
}
