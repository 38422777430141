import styled from "@emotion/styled";

const Header = styled.div`
  width: 100%;
  padding: 1rem;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
`;

export default Header;
