import React from "react";
import "./App.css";
import MainRoutes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./config/theme";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <MainRoutes />
        <ToastContainer
          position="top-right"
          limit={3}
          autoClose={5000}
          pauseOnHover
          closeOnClick
        />
      </ThemeProvider>
    </>
  );
}

export default App;
