import { toast } from "react-toastify";

interface AxiosError {
  response: { data: { statusCode: number } };
  request: object;
}

const middleware = (axiosPromise: Promise<unknown>): Promise<unknown> => {
  return axiosPromise.catch((error: AxiosError) => {
    if (error.response) {
      const token = localStorage.getItem("auth-token");
      if (token) {
        if (error.response?.data?.statusCode === 401) {
          const destination = window.location.pathname.replace(
            /^\/.*\/([^/]*).*$/,
            "$1"
          );
          if (destination !== "login") {
            toast.error("Session has expired");
            setTimeout(() => {
              window.location.href = `/login`;
            }, 1000 * 3);
          }
        }
      } else {
        return error.response;
      }

      // Request made and server responded
    } else if (error.request) {
      // The request was made but no response was received
      toast.error("An error has occurred, please contact the administrator");
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error("Error de conexion");
    }
    return;
  });
};

export default middleware;
