import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#6CCEF4",
      main: "#11AEEB",
      dark: "#0A668A",
    },
    secondary: {
      light: "#918AE0",
      main: "#6B62D5",
      dark: "#4338CA",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});
