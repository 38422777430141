import styled from "@emotion/styled";

export const BoxInfoWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
  min-height: 6rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 0.5rem;
  padding: 1rem;
  margin: 1rem;
  margin-left: 0;
  margin-right: 0;
  box-sizing: border-box;
`;

export const TextWrapper = styled.div`
  width: 100%;
  align-self: center;
  justify-self: flex-start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
`;
