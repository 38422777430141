import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Context } from "../../context/app_context";
import { Button, Grid, Typography } from "@mui/material";
import close from "../../assets/close.png";
import InputText from "../inputs/InputText";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addItemValidationSchema } from "./addItemValidationSchema";
import { toast } from "react-toastify";
import { addItem, getBox } from "../../services/box/box";
import { ErrorMessage } from "../error/error";

const AddItemBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 1rem;
`;

const AddItemWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  position: relative;
  padding: 1rem;
`;

const CloseImgWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2rem;
  height: 2rem;
`;

const CloseImg = styled.img`
  width: 100%;
`;

const CellContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export default function AddItem() {
  const { box, setBox, showADModal, setLoading, setShowADModal } =
    useContext(Context);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(addItemValidationSchema) });

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    const addItemResponse = await addItem({
      item: { id: data.barcode },
      box: { id: box?.id, is_extra_location: box?.is_extra_location },
    });
    if (addItemResponse.status != 200) {
      toast.error(
        <ErrorMessage testId="change_location_error">
          {addItemResponse.data.message}
        </ErrorMessage>
      );
    } else {
      const boxResponse = await getBox({ barcode: box?.id });
      if (boxResponse.error) {
        toast.error(
          <ErrorMessage testId="change_location_error">
            {boxResponse.data.message}
          </ErrorMessage>
        );
      }
      setBox(boxResponse.data);
      toast.success("Item Added");
    }
    reset({ barcode: "" });
    setLoading(false);
    setShowADModal(false);
  };

  if (showADModal) {
    return (
      <>
        <AddItemBackground>
          <AddItemWrapper>
            <CloseImgWrapper onClick={() => setShowADModal(false)}>
              <CloseImg src={close}></CloseImg>
            </CloseImgWrapper>
            <Typography variant="h5">Add Item</Typography>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <CellContent>
                  <Typography variant="body1" fontSize={"12px"}>
                    Type/Scan barcode to add item to the box
                  </Typography>
                </CellContent>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={8}>
                <CellContent>
                  <InputText
                    testId="add_item_barcode"
                    sx={{
                      minWidth: "17rem",
                      mt: 1,
                    }}
                    id={"barcode"}
                    label={"Barcode"}
                    control={control}
                    errors={errors}
                    required={false}
                    autoFocus={true}
                    InputProps={{
                      onKeyDown: (event: KeyboardEvent) => {
                        if (event.key === "Enter") {
                          handleSubmit(onSubmit)();
                        }
                      },
                    }}
                  />
                </CellContent>
              </Grid>
              <Grid item xs={4}>
                <CellContent>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ borderRadius: 100, color: "white", paddingX: "4rem" }}
                    onClick={handleSubmit(onSubmit)}
                    data-cy="add_item_submit"
                  >
                    Save
                  </Button>
                </CellContent>
              </Grid>
            </Grid>
          </AddItemWrapper>
        </AddItemBackground>
      </>
    );
  } else {
    return <></>;
  }
}
