import axios from "axios";
import middleware from "../middleware";
import { baseURL } from "../config";
import { validateRequest } from "../../common/validate_request";

export const getItem = async (data: any) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(`${baseURL}/item/find/${data.barcode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
