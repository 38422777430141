import axios from "axios";
import middleware from "../middleware";
import { baseURL } from "../config";

export const login = async (data: any) => {
  const response: any = await middleware(
    axios.post(`${baseURL}/auth/login`, data)
  );
  return response;
};
export const me = async () => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(`${baseURL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  return response.data;
};
