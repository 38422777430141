import React, { useContext } from "react";
import styled from "@emotion/styled";
import loadingIcon from "../../assets/loading_icon.svg";
import { Context } from "../../context/app_context";

const Loadingbackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const LoadingImageWrapper = styled.div`
  width: 6rem;
  height: 6rem;
`;

const LoadingImage = styled.img`
  width: 100%;
`;

export default function Loading() {
  const { loading } = useContext(Context);

  if (loading) {
    return (
      <>
        <Loadingbackground>
          <LoadingImageWrapper>
            <LoadingImage src={loadingIcon} />
          </LoadingImageWrapper>
        </Loadingbackground>
      </>
    );
  } else {
    return <></>;
  }
}
