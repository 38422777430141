import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { Context } from "../../context/app_context";
import { deleteBox } from "../../services/box/box";

const DeleteBoxWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function DeleteBox({ boxId }: { boxId: number }) {
  const { setLoading, setBox } = useContext(Context);

  const onPrint = async () => {
    setLoading(true);
    const PrintQueueResponse = await deleteBox(boxId);
    if (PrintQueueResponse.status === 200) {
      setBox(undefined);
      toast.success("Box deleted");
    } else {
      toast.error("Can't delete the box");
    }
    setLoading(false);
  };
  return (
    <>
      <DeleteBoxWrapper>
        <Button
          variant="contained"
          color="error"
          sx={{
            fontSize: "1rem",
            borderRadius: 100,
            color: "white",
            paddingX: "2rem",
            gridArea: "1 / 1 / 3 / 3",
          }}
          onClick={onPrint}
        >
          Delete Box
        </Button>
      </DeleteBoxWrapper>
    </>
  );
}
