import React from "react";
import DashboardTabs from "../components/dashboard/tabs";
import TabsWrapper from "../components/dashboard/wrapper";
import { AppContextProvider } from "../context/app_context";

export default function Dashboard() {
  return (
    <>
      <AppContextProvider>
        <TabsWrapper>
          <DashboardTabs></DashboardTabs>
        </TabsWrapper>
      </AppContextProvider>
    </>
  );
}
