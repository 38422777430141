import styled from "@emotion/styled";

const LoginWrapper = styled.div`
  width: 100%;
  min-height: 80vh;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default LoginWrapper;
