import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./components/protected";
import MainLayout from "./layouts/main";
import Dashboard from "./pages/dashboard";
import ExtLogin from "./pages/ext_login";
import Login from "./pages/login";

export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route
            index
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          ></Route>

          <Route path="login" element={<Login />} />
          <Route path="ext_login" element={<ExtLogin />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
